import React from 'react';
import '../../App.css';
import {Box, Button, Container} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import useFetch from "../../api/use_fetch";
function EmailsList() {

    const {data,loading,error} = useFetch("template/all","GET",null,null);
    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'subject',
            headerName: 'Subject',
            flex: 6,

        },
        {
            field: 'name',
            headerName: 'Email Name',
            flex: 6,

        },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                const onClick = (e) => {
                    console.log(params);

                    e.stopPropagation();
                    window.open("/dashboard/email/"+params.row.id, "_self");
                };
                return <Button onClick={onClick}>Edit</Button>;
            }
        },
    ];

    let rows = [];
    if(data){
        rows = data.map((email) => {
            return email;
        });
    }

    return (
        <div>
            <div style={{ display: loading ? 'flex' : 'none' }} className='modal'>
                <div className='modal-content'>
                    <div className='loader'></div>
                    <div className='modal-text'>Loading...</div>
                </div>
            </div>
            <Container
                maxWidth={true}
                sx={{
                    backgroundColor: '#129C9E',
                    paddingY: 1,
                }}
            >
                <h3
                    style={
                    {
                            color: 'white',

                        }

                    }
                >Emails List</h3>
            </Container>
            <Box sx={{ height: '100%', width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 25,
                            },
                        },
                    }}
                    pageSizeOptions={[5,10,25,50,100]}
                    disableRowSelectionOnClick
                    checkboxSelection={false}
                />
            </Box>
        </div>

    );
}

export default EmailsList;