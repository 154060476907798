import React, {useState} from 'react';
import '../../App.css';
import {AppBar, Box, CssBaseline, IconButton, Toolbar, Typography} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import SideNav from "./sidenav";
import EmailsList from "./emails_list";
import EmailDetails from "../email_details";
import Settings from "./settings";
import {useParams} from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';

const drawerWidth = 240;



function Dashboard({isEmailDetails}) {
    const params= useParams();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(Number(params.tab) ?? 0);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const handleOnSelectedTab = (tab) => {
        if(isEmailDetails){
            window.open("/dashboard/"+tab, "_self");

            return;
        }
        setSelectedTab(tab);
    };
    function renderElement(){
        if(isEmailDetails){
            return <EmailDetails/>;
        }
        console.log(selectedTab);
        if(selectedTab === 0)
            return <EmailsList/>;
        else if(selectedTab === 1)
            return <Settings/>;
        return <EmailsList/>;
    }
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Box
                        width={'100%'}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant="h6" noWrap component="div">
                            Email Listing Screen
                        </Typography>
                        <IconButton
                            color="inherit"
                            onClick={() => {
                                localStorage.removeItem('token');
                                window.open("/", "_self");
                            }}
                        >
                            <LogoutIcon/>
                        </IconButton>
                    </Box>

                </Toolbar>
            </AppBar>
            <SideNav
                onHandleDrawerToggle={handleDrawerToggle}
                handleOnSelectedTab={handleOnSelectedTab}
                mobileOpen={mobileOpen}
            />
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />

                {renderElement()}

            </Box>
        </Box>
    );
}

export default Dashboard;