import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import Login from "./ui/login";
import Dashboard from "./ui/dashboard/dashboard";
import {createTheme, ThemeProvider} from "@mui/material";
import Protected from "./ui/Protected";

function App() {
    const [isLoggedIn] = useState(localStorage.getItem('token') !== null);

    useEffect(() => {
        console.log(window.location.href.includes("/dashboard"));
        if (isLoggedIn && window.location.href.includes("/dashboard") === false) {
            window.open("/dashboard", "_self");
        }
    }, []
    );
  const theme =  createTheme(
      {
        palette: {
          primary: {
            main: '#129C9E',
          },
          secondary: {
            main: '#129C9E',
          },
        },
      }
  );
  return (
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
                  path="/dashboard/:tab?"
                  element={
                      <Protected isLoggedIn={isLoggedIn}>
                          <Dashboard />
                      </Protected>
                  }
              />
              <Route
                  path="/dashboard/email/:id"
                  element={
                      <Protected isLoggedIn={isLoggedIn}>
                          <Dashboard  isEmailDetails={true} />
                      </Protected>
                  }
              />
          </Routes>
        </Router>
      </ThemeProvider>
  );
}

export default App;
