import React from 'react';
import '../../App.css';
import {Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar} from "@mui/material";
import MailIcon from '@mui/icons-material/Mail';
import SettingsIcon from '@mui/icons-material/Settings';


const drawerWidth = 240;

function SideNav(
    {
        mobileOpen,
        onHandleDrawerToggle,
        handleOnSelectedTab
    }
) {

    const items = [
        {
            'name': 'Emails',
            'icon': <MailIcon />,
        },
        {
            'name': 'General Settings',
            'icon': <SettingsIcon />,
        },
    ];


    const drawer = (
        <div>
            <Toolbar >
                NextCalm
                Admin Panel
            </Toolbar>
            <Divider />
            <List>
                {items.map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton
                        onClick={() => handleOnSelectedTab(index)}
                        >
                            <ListItemIcon>
                                {text.icon}
                            </ListItemIcon>
                            <ListItemText primary={text.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );


    return (
        <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
        >
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={onHandleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open
            >
                {drawer}
            </Drawer>
        </Box>
    );
}

export default SideNav;