import React, {useState} from 'react';
import '../App.css';
import '../assets/next_calm_logo.png';
import {Button, Container, TextField} from "@mui/material";


function Login() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState(true);
    const [password, setPassword] = useState(null);

    function handleLogin() {
        if(email === '' ){
            setError("Please enter email");
            return;
        }
        if(password === ''){
            setError("Please enter password");
            return;
        }
        setLoading(true);
        setError(null);
        const options = {
            method: 'POST',
            headers: new Headers({'content-type': 'application/json'}),
        };
        options.body = JSON.stringify({
            email: email,
            password: password,
        });
        fetch( `${process.env.REACT_APP_API_URL}/cms/authenticate`, options)
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                console.log('data:', data);
                if(data.hasOwnProperty("isSuccess")){
                    if(data.isSuccess){
                        localStorage.removeItem('token');
                        localStorage.setItem('token', data.jwtToken);
                        console.log(localStorage.getItem('token'));
                        window.open("/dashboard", "_self");
                        return;
                    }
                    setError(data.message);
                }else{
                    if(data.jwtToken){
                        localStorage.removeItem('token');
                        localStorage.setItem('token', data.jwtToken);
                        console.log(localStorage.getItem('token'));
                        window.open("/dashboard", "_self");
                    }
                }


            })
            .catch(error => {
                console.error('error:', error);
                setLoading(false);
                console.log(error.typeof );

                setError(error);
            });
    }

    return (
        <div className="App">
            <div style={{ display: loading ? 'flex' : 'none' }} className='modal'>
                <div className='modal-content'>
                    <div className='loader'></div>
                    <div className='modal-text'>Loading...</div>
                </div>
            </div>
            <Container component="main" maxWidth="xs">
                <header className="App-header">

                    <img
                        src={require('../assets/next_calm_logo.png')}
                        alt="logo"
                        width="200"
                        height="200"
                    />
                    <h3>
                        Email Editor
                    </h3>



                    <TextField
                        variant="filled"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        onChange={e => setEmail(e.target.value)}
                        label="Email Address"
                        name="email"
                        sx={{
                            input: {
                                color: "black",
                                background: "white",
                            }
                        }}
                        InputProps={
                        {  disableUnderline: true,}}
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        error={error !== null}
                        helperText={error ===null ? '': String(error)}
                        margin="normal"
                        variant="filled"
                        onChange={e => setPassword(e.target.value)}
                        sx={{
                            input: {
                                color: "black",
                                background: "white",

                            }
                        }}
                        InputProps={
                            {  disableUnderline: true,
                            }
                    }
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />

                    <br/>
                    <Button
                        fullWidth
                        type={"submit"}
                        style={{ border: '2px solid',borderRadius: '16px', borderColor: 'white', color: 'white'}}
                        color="secondary"
                        onClick={handleLogin}
                        variant="outlined">
                        <h3>LOGIN</h3>
                    </Button>

                </header>
            </Container>
        </div>

    );
}

export default Login;