import React, {useEffect, useRef, useState} from 'react';
import '../App.css';
import {Box, Button, Container, TextField, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import useFetch from "../api/use_fetch";

function EmailDetails() {
    const params= useParams();
    const {data,loading} = useFetch(`template/${params.id}?id=${params.id}`,"GET",null,null);
    const {data: configData,loading:configLoading,} = useFetch(`template/config`,"GET",null,null);
    const fileInputRef=useRef();
    const [file, setFile] = useState(null);
    const [fromEmail, setFromEmail] = useState('');
    const [fromName, setFromName] = useState('');
    const [templateName, setTemplateName] = useState('');
    const [subject, setSubject] = useState('');
    const [testEmail, setTestEmail] = useState('');
    useEffect(() =>{
        if(data){
            setTemplateName(data.name)
            setFromName(data.from);
            setSubject(data.subject);
        }


    },[data]);
    useEffect(() =>{

        if(configData){
            setFromEmail(configData.fromEmail);
        }


    },[configData]);

    function handleChange(event) {
        setFile(event.target.files[0])
    }

    function sendTestEmail() {

        if(testEmail === '' ){
            alert("Please enter test email");
            return;
        }
        const options = {
            method: 'POST',
            headers: new Headers({'content-type': 'app' +
                    'lication/json'}),
        };
        options.body = JSON.stringify({
            templateType: data.type,
            id: data.id,
            name: templateName,
            subject: subject,
            from:fromName?fromName:"Guardian Bot",
            representativeName: 'Test User',
            email: testEmail,
            userId: 68,
        });
        fetch( `${process.env.REACT_APP_API_URL}/template/test`, options)
            .then(response => response.json())
            .then(data => {
                console.log('data:', data);
                if(data.hasOwnProperty("isSuccess")){
                    if(data.isSuccess){
                        alert("Test email sent Successfully");

                    }
                }else{
                    console.error('error:', data);
                }
            })
            .catch(error => {
                console.error('error:', error);
            });
    }
    function submitData() {
        const formData = new FormData();
        formData.append('Id', params.id);
        formData.append('file', file?file:null);
        formData.append('Name', templateName);
        formData.append('From', fromName);
        formData.append('Subject', subject);
        formData.append('Path', file?file.name:`${templateName}.html.html`);
        console.log(fromName)
        fetch(`${process.env.REACT_APP_API_URL}/template/update-template`, {
            method: 'PATCH',
            body: formData,
        })
            .then(response => response.json())
            .then(data1 => {
                console.log(data1);
                if(data1.hasOwnProperty("id")){
                        alert("Email template saved Successfully");
                }
            })
            .catch(error => {
                console.error(error)
            })
    }

    return (
        <div>
            <div style={{ display: loading || configLoading ? 'flex' : 'none' }} className='modal'>
                <div className='modal-content'>
                    <div className='loader'></div>
                    <div className='modal-text'>Loading...</div>
                </div>
            </div>
                <Container
                    maxWidth
                    sx={{
                        backgroundColor: '#129C9E',
                        paddingY: 1,
                    }}
                >
                    <h3
                        style={
                            {
                                color: 'white',

                            }

                        }
                    >{data && data.name}</h3>
                </Container>
            <br/>
            <Box
                maxWidth="sm"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',

                }}
            >
                <Typography
                    sx={{
                        fontWeight: 'bold',
                    }}
                    variant="h6">
                    From Email:
                </Typography>
                <div style={{width:'32px'}}/>


                   <Typography variant="h6">{fromEmail}</Typography>


            </Box>
            <Box
                maxWidth="sm"
                flexDirection="column"
                alignItems="center"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',

                }}
            >
                <Typography
                    fullWidth
                    sx={{
                        fontWeight: 'bold',
                    }}
                    variant="h6">
                    From Name:
                </Typography>
                <div style={{width:'32px'}}/>

                <TextField
                    fullWidth
                    margin="normal"
                    required
                    value={fromName}
                    id="fromName"
                    label="From Name"
                    name="fromName"
                    onChange={(e)=>setFromName(e.target.value)}
                    sx={{
                        input: {
                            color: "black",
                            background: "white",
                        }
                    }}
                    InputProps={
                        {  disableUnderline: true,}}

                />


            </Box>
            <Box
                maxWidth="sm"
                flexDirection="column"
                alignItems="center"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',

                }}
            >
                <Typography
                    fullWidth
                    sx={{
                        fontWeight: 'bold',
                    }}
                    variant="h6">
                    Template Name:
                </Typography>
                <div style={{width:'32px'}}/>

                <TextField
                    fullWidth
                    margin="normal"
                    required
                    value={templateName}
                    id="templateName"
                    label="template Name"
                    name="templateName"
                    onChange={(e)=>setTemplateName(e.target.value)}
                    sx={{
                        input: {
                            color: "black",
                            background: "white",
                        }
                    }}
                    InputProps={
                        {  disableUnderline: true,
                            readOnly:true,
                        }}
                        
                    

                >
                    </TextField>


            </Box>
            <Box
                maxWidth="sm"
                flexDirection="column"
                alignItems="center"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Typography
                    fullWidth
                    sx={{
                        fontWeight: 'bold',
                    }}
                    variant="h6">
                    Subject:
                </Typography>
                <div style={{width:'32px'}}/>

                <TextField
                    fullWidth
                    margin="normal"
                    required
                    value={subject}
                    id="subject"
                    label="Subject"
                    name="subject"
                    onChange={(e)=>setSubject(e.target.value)}
                    sx={{
                        input: {
                            color: "black",
                            background: "white",
                        }
                    }}
                    InputProps={
                        {  disableUnderline: true,}}

                />


            </Box>
            <br/>
            <Box
                maxWidth="sm"
                sx={{
                    display: 'flex',

                    flexDirection: 'column',
                }}
            >
                <Button
                    onClick={()=>fileInputRef.current.click()}
                    variant={'contained'} >Upload HTML</Button>
                <input onChange={handleChange} accept=".html"  multiple={false} ref={fileInputRef} type='file' hidden/>
                { file && <Typography variant="h6">{file.name}</Typography>}
                <div style={{height:'16px'}}/>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >


                    <TextField

                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        onChange={(e)=>setTestEmail(e.target.value)}
                        sx={{
                            input: {
                                color: "black",
                                background: "white",
                            }
                        }}
                        InputProps={
                            {  disableUnderline: true,}}
                        autoComplete="email"
                        autoFocus
                    />
                    <div style={{width:'16px'}}/>
                    <Button
                        variant={'contained'}
                        onClick={sendTestEmail}
                    >Send Test</Button>

                </Box>


            </Box>
            <footer style={{width:'80%', position: "fixed", bottom: 32}}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap:"wrap",
                        justifyContent: 'space-between',
                    }}
                >
                    <Button
                        variant={'contained'}
                        onClick={submitData}
                    >Save Changes</Button>

                    <Button
                        color={'error'}
                        variant={'outlined'}

                        onClick={() => {

                            window.open("/dashboard", "_self");
                        }}
                    >Cancel Changes</Button>



                </Box>
            </footer>
        </div>
    );
}

export default EmailDetails;