import {Box, Button, Container, TextField, Typography} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import useFetch from "../../api/use_fetch";

function Settings() {
    const {data: configData,loading:configLoading,error:configError} = useFetch(`template/config`,"GET",null,null);

    const [fromEmail, setFromEmail] = useState('');
    useEffect(() =>{

        if(configData){
            setFromEmail(configData.fromEmail);
        }


    },[configData]);

    function handleUpdateConfig() {

        if(fromEmail === '' ){
            alert("Please enter from email");
            return;
        }
        const options = {
            method: 'PATCH',
            headers: new Headers({'content-type': 'application/json'}),
        };
        options.body = JSON.stringify({
            id: configData.id,
            fromName: configData.fromName,
            fromEmail: fromEmail,
        });
        fetch( `${process.env.REACT_APP_API_URL}/template/update-config`, options)
            .then(response => response.json())
            .then(data => {
                console.log('data:', data);
                if(data.hasOwnProperty("id")){
                    if(data.id === configData.id){
                        alert("Config Updated Successfully");

                    }
                }else{
                    console.error('error:', data);
                }
            })
            .catch(error => {
                console.error('error:', error);
            });

    }
    return (
        <div>
            <div style={{ display:  configLoading ? 'flex' : 'none' }} className='modal'>
                <div className='modal-content'>
                    <div className='loader'></div>
                    <div className='modal-text'>Loading...</div>
                </div>
            </div>
            <Container
                maxWidth
                sx={{
                    backgroundColor: '#129C9E',
                    paddingY: 1,
                }}
            >
                <h3
                    style={
                        {
                            color: 'white',

                        }

                    }
                >General Settings</h3>
            </Container>
            <br/>
            <Box
                maxWidth="sm"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',

                }}
            >
                <Typography
                    sx={{
                        fontWeight: 'bold',
                    }}
                    variant="h6">
                    From Email:
                </Typography>
                <div style={{width:'32px'}}/>

                <TextField
                    fullWidth
                    margin="normal"
                    required
                    value={fromEmail}
                    id="fromEmail"

                    label="From Email"
                    name="fromEmail"
                    type="email"
                    onChange={(e)=>setFromEmail(e.target.value)}
                    sx={{
                        input: {
                            color: "black",
                            background: "white",
                        }
                    }}
                    InputProps={
                        {  disableUnderline: true,}}

                />


            </Box>
            <br/>
            <Button
            onClick={handleUpdateConfig}
                variant={'contained'}
            >Save Changes</Button>
        </div>
    );
}

export default Settings;