import {useState, useEffect} from "react";


const useFetch = (url,method,body,headers) =>  {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const token = localStorage.getItem('token');
    useEffect(() => {

        fetch( `${process.env.REACT_APP_API_URL}/${url}`, {
            method: method,
            body: method === "GET" ? null: JSON.stringify(body),
            headers: {
                "Authorization":token,
                ...headers,
            },
        })
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                console.log('data:', data);
                setData(data);
            })
            .catch(error => {
                console.error('error:', error);
                setLoading(false);
                setError(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    return {data,loading,error};
}

export default  useFetch;